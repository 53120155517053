import React from "react";
import Person from "../../assets/Person.png";
import { motion } from "framer-motion";

const RightSide = () => {
  return (
    <div>
      <motion.svg
        viewBox="0 0 787 820"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className="lg:-translate-y-20 lg:w-[787px] mt-20 lg:mt-0 lg:h-[820px] w-[400px] h-[400px]"
      >
        <rect
          x="298.985"
          y="257.683"
          width="377.337"
          height="377.337"
          rx="23.179"
          transform="rotate(23.639 298.985 257.683)"
          stroke="white"
          stroke-width="2"
        />
        <rect
          x="327.764"
          y="322.09"
          width="379.337"
          height="379.337"
          rx="24.179"
          transform="rotate(23.639 327.764 322.09)"
          fill="#5ACBC9"
          stroke="white"
          stroke-width="4"
        />
        <mask
          id="mask0_167_571"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="180"
          y="327"
          width="490"
          height="490"
        >
          <rect
            x="327.264"
            y="322.09"
            width="379.337"
            height="379.337"
            rx="24.179"
            transform="rotate(23.639 327.264 322.09)"
            fill="#5ACBC9"
            stroke="white"
            stroke-width="4"
          />
        </mask>
        <g mask="url(#mask0_167_571)">
          <path
            d="M183.794 438.725C228.961 463.725 332.794 498.725 386.794 438.725C454.294 363.725 585.794 378.725 645.794 397.225"
            stroke="white"
          />
          <path
            d="M184.993 466.831C231.648 488.931 337.483 517.308 387.589 454.019C450.221 374.909 582.405 381.579 643.453 396.255"
            stroke="white"
          />
          <path
            d="M179.363 495.321C226.017 517.421 331.853 545.798 381.958 482.509C444.59 403.399 576.775 410.069 637.823 424.745"
            stroke="white"
          />
          <path
            d="M173.363 524.321C220.017 546.421 325.853 574.798 375.958 511.509C438.59 432.399 570.775 439.069 631.823 453.745"
            stroke="white"
          />
          <path
            d="M173.363 524.321C220.017 546.421 325.853 574.798 375.958 511.509C438.59 432.399 570.775 439.069 631.823 453.745"
            stroke="white"
          />
          <path
            d="M185.363 623.321C237.563 645.07 356.146 672.641 412.881 608.933C483.8 529.298 632.129 534.948 700.544 549.158"
            stroke="white"
          />
          <path
            d="M173.363 667.908C225.563 689.657 344.146 717.228 400.881 653.52C471.8 573.885 620.129 579.535 688.544 593.745"
            stroke="white"
          />
          <path
            d="M175.363 699.908C227.563 721.657 346.146 749.228 402.881 685.52C473.8 605.885 622.129 611.535 690.544 625.745"
            stroke="white"
          />
          <path
            d="M190.363 731.908C242.563 753.657 361.146 781.228 417.881 717.52C488.8 637.885 637.129 643.535 705.544 657.745"
            stroke="white"
          />
          <path
            d="M215.363 779.908C267.563 801.657 386.146 829.228 442.881 765.52C513.8 685.885 662.129 691.535 730.544 705.745"
            stroke="white"
          />
          <path
            d="M208.363 593.321C255.017 615.421 360.853 643.798 410.958 580.509C473.59 501.399 605.775 508.069 666.823 522.745"
            stroke="white"
          />
          <path
            d="M167.363 557.321C219.878 579.05 339.187 606.575 396.3 542.843C467.69 463.179 616.938 468.77 685.772 482.954"
            stroke="white"
          />
        </g>
        <mask
          id="mask1_167_571"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="84"
          y="97"
          width="635"
          height="716"
        >
          <path
            d="M266.364 143.902C268.119 142.248 270.132 140.891 272.324 139.884L361.225 99.0623C364.403 97.6028 367.882 96.9156 371.377 97.057L529.95 103.473C534.769 103.668 539.393 105.429 543.122 108.488L685.956 225.685C689.78 228.823 692.446 233.15 693.528 237.977L718.133 347.664C719.099 351.971 718.758 356.468 717.154 360.579L672.112 476.034L530.505 799.559C525.593 810.78 512.515 815.895 501.294 810.984L185.662 672.832C180.585 670.609 176.523 666.566 174.278 661.499L107.299 510.345C106.841 509.311 106.462 508.244 106.167 507.153L85.4832 430.713C84.4055 426.73 84.4601 422.525 85.641 418.572L105.967 350.524C106.395 349.091 106.967 347.705 107.673 346.388L163.331 242.619C164.46 240.514 165.923 238.604 167.661 236.965L266.364 143.902Z"
            fill="#C4C4C4"
          />
        </mask>
        <g mask="url(#mask1_167_571)">
          <rect
            x="-10.9999"
            y="180"
            width="908"
            height="813"
            fill="url(#pattern0)"
          />
        </g>
        <defs>
          <pattern
            id="pattern0"
            patternContentUnits="objectBoundingBox"
            width="1"
            height="1"
          >
            <use
              xlinkHref="#image0_167_571"
              transform="scale(0.000244171 0.000272702)"
            />
          </pattern>
          <motion.image
            initial={{ y: 700 }} // start from the bottom
            animate={{ y: -400 }} // animate to the original position
            transition={{
              type: "spring",
              stiffness: 50,
              damping: 20,
              duration: 1.0,
            }} // control the animation
            id="image0_167_571"
            width="4096"
            height="3667"
            xlinkHref={Person}
            className="-translate-y-[27rem]"
          />
        </defs>
      </motion.svg>
    </div>
  );
};

export default RightSide;
